import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLocation, faPhone} from '@fortawesome/free-solid-svg-icons';

function Contact() {
  return (
   <div id='contact'>
    <h4 className='text-3xl bg-light py-4 font-bold text-center content-title'>Contact Us</h4>
     <div className='my-12 container'>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mx-auto pb-5" data-aos="fade-up" data-aos-offset="500" data-aos-easing="ease-in-sine">
            <div className="row pb-3">
              <div className="row col-md-6 ">
                <div className='col-4 text-right'><FontAwesomeIcon className="text-3xl pt-2  text-red-600" icon={faPhone} /></div>
                <div className='col-8 text-xl'> +233 (0) 322 023 430 <br/><span className=''> +233 (0) 266 116 627</span></div>
             </div>
              <div className="row col-md-6">
                <div className='col-4 text-right'><FontAwesomeIcon className="text-3xl pt-2 text-red-600" icon={faLocation} /></div>
                <div className='col-8 text-xl'>
                No.16, Cedar Cresent <br /> <span className=''> Danyame, Kumasi</span>
               </div>    
             </div>
            </div>
            <p className='my-2 text-center text-xl'><a href="#facebook" className="mr-3"> <FontAwesomeIcon className="text-2xl text-red-600" icon={faEnvelope} /></a> Xmingeradvertisingchannel@gmail.com</p>
          </div>
        </div>
      </div>
     <div className='mt-2 mb-3'>
          
         
          
          </div>
          <hr  className='my-2'/>
      <hr />
      
      
      <div className="row pt-3 mx-auto">
      
        <div className="col-md-6 p-12 mx-2 " id='contact-map'>
          <div >
             {/* <iframe id="gmap_canvas" src="https://maps.google.com/maps?q=<?php// echo $row['address']; ?>&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
									
          	</iframe>  */}
            
             {/* <iframe id="gmap_canvas"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2990.274257380938!2d-70.56068388481569!3d41.45496659976631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e52963ac45bbcb%3A0xf05e8d125e82af10!2sDos%20Mas!5e0!3m2!1sen!2sus!4v1671220374408!5m2!1sen!2sus"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          */}
            <iframe id="gmap_canvas"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3962.7217584783234!2d-1.6363630268897833!3d6.681350593313867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfdb96de161d38a1%3A0x7653d0f4636f651c!2sXminger%20Advertising!5e0!3m2!1sen!2sgh!4v1716804407201!5m2!1sen!2sgh"   allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
         
          </div>
          
        </div>
        <div className="col-md-5 p-5 mx-2" id='contact-form'>
          
       
            <form class="row g-3" >
            <h5 className='text-xl capitalize font-bold'>Send us a Message</h5>
                  <div class="col-md-6">
                    
                    <label for="inputPassword4" class="form-label">Fullname</label>
                    <input type="text" class="form-control" id="inputPassword4"/>
                  </div>
                  <div class="col-md-6">
                    <label for="inputEmail4" class="form-label">Email</label>
                    <input type="email" class="form-control" id="inputEmail4"/>
                  </div>
            
                <div class="col-12">
                  <label for="inputAddress" class="form-label">Subject</label>
                  <input type="text" class="form-control" id="inputAddress" placeholder=""/>
                </div>
                <div class="col-12">
                  <label for="inputAddress2" class="form-label">Message</label>
                  <textarea name="" id="" className='form-control'></textarea>
                </div>
          
            <div class="col-12">
              <button type="submit" class="btn btn-primary">Send Message</button>
            </div>
          </form>
        </div>
      </div>
      
    </div>
   </div>
  )
}

export default Contact