import React from 'react'
import imgs from "../images/img4.jpg"
import about_image1 from "../images/img1.jpg"
import about_image2 from "../images/img6.jpg"
// import blac from '../images/black.png'
// import about_img from '../images/about-img.png'
// import design from '../images/design.png'
import about_image3 from "../images/site.jpg"
import about_image4 from "../images/site_banner.jpg"


function About() {
  return (
    <>
    <div className=' pb-' id='about'>
      
    <div className="row" id='about-txt'>
  
         <div className="col-12">
          <img className='about-img' src={about_image3} alt="" />
          <img className='about-img' src={about_image4} alt="" />
          {/* <img className='about-img' src={about_image4} alt="" /> */}
         </div>
         <div className="row">
        <div className="col-12  mb-5">
          {/* <img className='about-img' src={about_image3} alt="" /> */}
         
         </div>
        </div>
      </div>
     <div className="container">
    
        </div>
       
        <div className="container">
      <div className="row">
        <div className="col-md-4">
          <div className="card hover:opacity-80" data-aos="fade-up" data-aos-offset="100" data-aos-easing="ease-in-sine">
            <div className="card-body">
              <img className='abuot-img' src={imgs} alt="" />
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card hover:opacity-80" data-aos="fade-up" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <div className="card-body">
              <img className='abuot-img' src={about_image2} alt="" />
            </div>
            {/* <div className="card-title hidden hover:block">
              <p>good name</p>
            </div> */}
          </div>
        </div>
        <div className="col-md-4">
          <div className="card hover:opacity-80" data-aos="fade-up" data-aos-offset="500" data-aos-easing="ease-in-sine">
            <div className="card-body">
              <img className='abuot-img' src={about_image1} alt="" />
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
    

    </>
  )
}

export default About